














































import { Component, Vue } from "vue-property-decorator";
import { FeatherIcon } from "vue-feather-icons";

@Component({
  components: {
    FeatherIcon,
  },
})
export default class Logout extends Vue {
  title = "Adios!";
  message = "Hope to see you again soon...";
}
